import React, { memo } from 'react'
import Colors, { Color } from 'spartacus/styles/colors'
import { StyledComponent } from 'styled-components'
import * as S from './style'

export const ALL_TYPOGRAPHY_KINDS = [
  'TSB - Button',
  'TSL - Legal',
  'TSL - Light Beta',
  'TSC - Callout',
  'TS1 - Regular',
  'TS1 - Strong',
  'TS2 - Regular',
  'TS2 - Strong',
  'TS3 - Regular',
  'TS3 - Strong',
  'TS4 - Regular',
  'TS4 - Strong',
  'TS5 - Regular',
  'TS5 - Strong',
  'TS6 - Regular',
  'TS6 - Strong',
  'TS7 - Regular',
  'TS7 - Strong',
  'TS8 - Regular',
  'TS8 - Strong',
] as const

type TypographyKindTuple = typeof ALL_TYPOGRAPHY_KINDS
export type TypographyKind = TypographyKindTuple[number]

export type TypographyElement = 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'a'

export interface Props {
  kind?: TypographyKind
  xs?: TypographyKind
  sm?: TypographyKind
  md?: TypographyKind
  lg?: TypographyKind
  xl?: TypographyKind
  element?: TypographyElement
  children: React.ReactNode
  color?: Color
  className?: string
  style?: React.CSSProperties
  href?: string
  onClick?: () => void
}

const getContainerFromElement = (
  element: Props['element'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): StyledComponent<any, any, {}, never> => {
  switch (element) {
    case 'p':
      return S.P

    case 'h1':
      return S.H1

    case 'h2':
      return S.H2

    case 'h3':
      return S.H3

    case 'h4':
      return S.H4

    case 'h5':
      return S.H5

    case 'a':
      return S.A

    default:
      return S.Span
  }
}

const Typography = React.forwardRef(
  (
    {
      kind = 'TS2 - Regular',
      xs,
      sm,
      md,
      lg,
      xl,
      element = 'span',
      children,
      color = Colors.Type.Primary,
      style,
      className,
      href,
      onClick,
    }: Props,
    ref,
  ): JSX.Element => {
    const Container = getContainerFromElement(element)

    return (
      <Container
        data-kind={kind}
        data-xs={xs}
        data-sm={sm}
        data-md={md}
        data-lg={lg}
        data-xl={xl}
        data-color={color}
        className={className}
        style={style}
        href={href}
        ref={ref}
        onClick={onClick}
      >
        {children}
      </Container>
    )
  },
)

export default memo(Typography)
