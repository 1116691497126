import Constants from 'spartacus/constants'

const breakpointsDict = {
  xs: `(min-width: ${Constants.BREAKPOINTS.get('xs')}px)`,
  sm: `(min-width: ${Constants.BREAKPOINTS.get('sm')}px)`,
  md: `(min-width: ${Constants.BREAKPOINTS.get('md')}px)`,
  lg: `(min-width: ${Constants.BREAKPOINTS.get('lg')}px)`,
  xl: `(min-width: ${Constants.BREAKPOINTS.get('xl')}px)`,
  xxl: `(min-width: ${Constants.BREAKPOINTS.get('xxl')}px)`,
  nColumns: (n: number): string => {
    const totalColumns = 27
    const fraction = (n / totalColumns) * 100
    const width = Math.round((fraction + Number.EPSILON) * 100) / 100

    // Round to nearest hundredth
    return `${width}vw`
  },
}

export default breakpointsDict
