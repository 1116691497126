import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import Dimensions from 'spartacus/styles/dimensions'
import Colors, { Color } from 'spartacus/styles/colors'
import { TypographyKind } from '.'

const regularFont = css`
  font-family: 'TT', sans-serif;
`

const mabryFont = css`
  font-family: 'Mabry', sans-serif;
`

const strongFont = css`
  ${mabryFont}
  font-weight: bold;
`

const getStyleFromKind = (kind: TypographyKind): FlattenSimpleInterpolation => {
  switch (kind) {
    case 'TSB - Button':
      return css`
        ${strongFont};
        font-size: 16px;
        line-height: 28px;
      `

    case 'TSL - Legal':
      return css`
        ${regularFont};
        font-size: 12px;
        line-height: 16px;
      `

    case 'TSL - Light Beta':
      return css`
        ${regularFont};
        font-size: 15px;
        line-height: 20px;

        a {
          color: ${Colors.Type.Primary};
          text-decoration: underline;

          &:hover {
            color: ${Colors.Brand.Primary1};
          }
        }
      `

    case 'TSC - Callout':
      return css`
        ${strongFont};
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.88px;
        text-transform: uppercase;
      `

    case 'TS1 - Regular':
      return css`
        ${regularFont};
        font-size: 18px;
        line-height: 20px;

        a {
          color: ${Colors.Type.Primary};
          text-decoration: underline;

          &:hover {
            color: ${Colors.Brand.Primary1};
          }
        }
      `

    case 'TS1 - Strong':
      return css`
        ${strongFont};
        font-size: 16px;
        line-height: 24px;
      `

    case 'TS2 - Regular':
      return css`
        ${regularFont};
        font-size: 22px;
        line-height: 28px;
      `

    case 'TS2 - Strong':
      return css`
        ${strongFont};
        font-size: 20px;
        line-height: 28px;
      `

    case 'TS3 - Regular':
      return css`
        ${regularFont};
        font-size: 32px;
        line-height: 36px;
      `

    case 'TS3 - Strong':
      return css`
        ${strongFont};
        font-size: 32px;
        line-height: 38px;
      `

    case 'TS4 - Regular':
      return css`
        ${regularFont};
        font-size: 40px;
        line-height: 48px;
      `

    case 'TS4 - Strong':
      return css`
        ${strongFont};
        font-size: 40px;
        line-height: 48px;
      `

    case 'TS5 - Regular':
      return css`
        ${regularFont};
        font-size: 48px;
        line-height: 57px;
      `

    case 'TS5 - Strong':
      return css`
        ${strongFont};
        font-size: 48px;
        line-height: 58px;
      `

    case 'TS6 - Regular':
      return css`
        ${regularFont};
        font-size: 56px;
        line-height: 67px;
      `

    case 'TS6 - Strong':
      return css`
        ${strongFont};
        font-size: 56px;
        line-height: 67px;
      `

    case 'TS7 - Regular':
      return css`
        ${regularFont};
        font-size: 96px;
        line-height: 115px;
      `

    case 'TS7 - Strong':
      return css`
        ${strongFont};
        font-size: 96px;
        line-height: 115px;
      `

    case 'TS8 - Regular':
      return css`
        ${regularFont};
        font-size: 156px;
        line-height: 186px;
      `

    case 'TS8 - Strong':
      return css`
        ${strongFont};
        font-size: 156px;
        line-height: 187px;
      `

    default:
      // TS2 - Regular
      return css`
        ${regularFont};
        font-size: 22px;
        line-height: 28px;
      `
  }
}

const getStyleFromProps = (props: Props): FlattenSimpleInterpolation => {
  let style = css`
    color: ${props['data-color']};
  `

  if (props['data-kind']) {
    style = css`
      ${style}
      ${getStyleFromKind(props['data-kind'])}
    `
  }

  if (props['data-xs']) {
    style = css`
      ${style}
      @media ${Dimensions.xs} {
        ${getStyleFromKind(props['data-xs'])}
      }
    `
  }

  if (props['data-sm']) {
    style = css`
      ${style}
      @media ${Dimensions.sm} {
        ${getStyleFromKind(props['data-sm'])}
      }
    `
  }

  if (props['data-md']) {
    style = css`
      ${style}
      @media ${Dimensions.md} {
        ${getStyleFromKind(props['data-md'])}
      }
    `
  }

  if (props['data-lg']) {
    style = css`
      ${style}
      @media ${Dimensions.lg} {
        ${getStyleFromKind(props['data-lg'])}
      }
    `
  }

  if (props['data-xl']) {
    style = css`
      ${style}
      @media ${Dimensions.xl} {
        ${getStyleFromKind(props['data-xl'])}
      }
    `
  }

  return style
}

interface Props {
  'data-kind': TypographyKind
  'data-xs'?: TypographyKind
  'data-sm'?: TypographyKind
  'data-md'?: TypographyKind
  'data-lg'?: TypographyKind
  'data-xl'?: TypographyKind
  'data-color': Color
}

export const P = styled.p<Props>`
  ${(props): FlattenSimpleInterpolation => getStyleFromProps(props)};
`
export const Span = styled.span<Props>`
  ${(props): FlattenSimpleInterpolation => getStyleFromProps(props)};
`
export const H1 = styled.h1<Props>`
  ${(props): FlattenSimpleInterpolation => getStyleFromProps(props)};
`
export const H2 = styled.h2<Props>`
  ${(props): FlattenSimpleInterpolation => getStyleFromProps(props)};
`
export const H3 = styled.h3<Props>`
  ${(props): FlattenSimpleInterpolation => getStyleFromProps(props)};
`
export const H4 = styled.h4<Props>`
  ${(props): FlattenSimpleInterpolation => getStyleFromProps(props)};
`
export const H5 = styled.h5<Props>`
  ${(props): FlattenSimpleInterpolation => getStyleFromProps(props)};
`
export const A = styled.a<Props>`
  ${(props): FlattenSimpleInterpolation => getStyleFromProps(props)};
`
